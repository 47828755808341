@import '../../../../../../style/variables.less';

.AddClubFormContainer {
  padding: 0 24px;
  max-width: 400px;
}

.AddClubFormGroup {
  gap: 10px;
}

.AddClubFormItem {
  max-width: 400px;
}

.AddClubFormItemUploader.ant-upload.ant-upload-drag {
  overflow: hidden;

  .ant-upload-btn {
    min-height: 250px;
    padding: 0;
  }
}

.AddClubAttribute {
  margin-bottom: 24px;
}

.AddClubAttributeTitle {
  margin-bottom: 16px;
  font-weight: normal;
}

.AddClubImageContent {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.AddClubImage {
  width: 100%;
}

.AddClubImageCover {
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  width: 100%;
  height: 100%;
  color: @gray-4;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;

  &.active,
  &:hover {
    transition: opacity 0.4s;
    opacity: 1;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';