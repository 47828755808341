@import '../../../../../../../style/variables.less';

.GroupingCreateButton {
  width: 100%;
}

.GroupingStepsContainer {
  width: 680px;

  .ant-btn-primary:not([disabled]) {
    color: #fff;
  }

  .ant-btn {
    line-height: 1;
    padding: 1px 8px;
  }
}

.GroupingSteps.ant-steps {
  width: 300px;
  margin: 0 auto;
}

.GroupingComponentModal .ant-modal-content {
  height: 800px;
}

.GroupingStepContent {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  padding: 16px;
  margin-top: 8px;

  .GroupingCategory {
    font-size: 14px;
    line-height: 22px;
  }

  .GroupingPlayerButton {
    height: auto;
    padding: 8px;

    > p,
    > span {
      line-height: 22px;
      margin-bottom: 0;
    }

    > span {
      font-size: 14px;
    }
  }

  .GroupingPlayerName {
    font-size: 24px;
    font-weight: 700;
  }

  .CreateTeamButtonContainer {
    display: block;
    width: 100%;
    text-align: center;
  }
  .CreateTeamButton {
    padding: 8px 16px;
    line-height: 24px;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';