@import '../../../style/variables.less';

.frame {
  height: 100%;
}

.frameMenuSideMobile.ant-layout-sider {
  position: absolute;
  z-index: 10;
  height: 100%;
}

.frameMenuSideMobile .ant-layout-sider-zero-width-trigger {
  top: 10px;
}

.frameMenuSide .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
}

.frameMenuSlogan {
  padding: 6px 16px;
}

.frameMenuLogoContainer {
  margin: 6px 0;
}

.frameLogo {
  margin: 8px;
}

.frameLogoText {
  margin: 8px;
  line-height: 1em;
  white-space: nowrap;
  font-weight: bold;
}

.frameMenuTitleDivider.ant-divider-horizontal {
  margin: 0 16px 16px;
  width: auto;
  min-width: 0;
}

.ant-menu-item .anticon + .frameMenuItemText {
  margin-left: 12px;
  font-size: 1.142857rem;
  font-weight: bold;
}

.frameMenu.ant-menu {
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
}

.frameMenuUserCenter.ant-menu {
  border-top: 2px solid @gray-4;
  padding: 24px 0;
}

.ant-menu-inline .frameMenuItem.ant-menu-item {
  padding: 0;
}

.ant-menu:not(.ant-menu-horizontal) .frameMenuItem.ant-menu-item-selected {
  background-color: transparent;
  box-shadow: 0 0 0 2px transparent;
}

.frameMenuItem.ant-menu-item.ant-menu-item-selected::after {
  border-right-width: 4px;
}

.frameMenuItem.ant-menu-item .anticon {
  padding-right: 8px;
  font-size: 1.142857rem;
}

.frameMenuItemSkeletonContainer {
  margin: 14px 24px;
  gap: 24px;

  .ant-skeleton.ant-skeleton-block .ant-skeleton-button {
    height: 18px;
  }
}

.frameMenuLogoutContainer {
  padding: 24px 0;
  border-top: 2px solid @gray-4;
}

.frameMenuLogout {
  padding: 0 16px;
  height: 40px;
  line-height: 40px;

  .action-logout {
    padding: 0 8px;
    font-size: 1.142857rem;
    color: @gray-2;
  }
}

.frameMenuLogoutText {
  display: inline-flex;
  margin-left: 12px;
  font-size: 1.142857rem;
  font-weight: bold;
  color: @gray-2;
  white-space: nowrap;
}

.logoutButton.ant-btn {
  padding: 0px;
}

.logoutButton.ant-btn .anticon,
.logoutButton.ant-btn span {
  transition: color 0.5s linear;
}
//logout text color change to @primary-color during 0.5s when hover on logout button
.logoutButton.ant-btn:hover .anticon,
.logoutButton.ant-btn:hover span {
  color: @primary-color;
}

.logoutButton.ant-btn .anticon {
  padding: 0 8px;
  color: #595959;
}

.header {
  border-bottom: 2px solid @gray-4;

  &.ant-layout-header {
    padding: 0 16px;
  }

  .anticon-menu-fold,
  .anticon-menu-unfold {
    padding: 8px;
    font-size: 1.5rem;
  }
}

.frameMenuSideCollapsed .frameMenuItem.ant-menu-item .anticon,
.frameMenuSideCollapsed .frameMenuLogout .action-logout {
  padding-right: 12px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';