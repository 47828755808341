@import '../../../../../../style/variables.less';

.PlayersAchievementImport {
  .ant-upload-list-item {
    display: none;
  }
}

.PlayersAchievementImportDescription {
  padding: 24px;
}

.PlayersAchievementImportImportButtons {
  margin: 24px 0;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';