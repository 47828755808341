@import '../../../../../../../../../style/variables.less';

.TargetSettingsContainer {
  flex: 1;
  overflow: scroll;
  position: relative;

  &Content {
    width: max-content;
  }
}

.TargetSetting {
  width: 100%;
  position: relative;

  &Table {
    &Header {
      &Button {
        height: auto;
        min-width: 28px;
        font-size: 18px;
        padding: 5px;
        font-weight: bolder;
        background: white;
        text-align: center;
        user-select: none;
        text-wrap: nowrap;
        user-select: none;
        cursor: pointer;

        &Description {
          font-size: 12px;
          font-weight: normal;
          color: gray;
          user-select: none;
        }
      }

      &ItemContent {
        background: @white;
      }

      &ItemContent:before {
        content: "";
        position: absolute;
        left: 0;
        top: -20px;
        right: 0;
        height: 20px;
        background: @white;
      }

      &ItemContent:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -20px;
        right: 0;
        height: 20px;
        background: @white;
      }

      &Item {
        min-width: 28px;
        text-align: center;
        border-radius: @border-radius-base;
        border: 1px solid @gray-4;
        margin: 0 5px;
        padding: 5px;
        font-size: 12px;
        user-select: none;
        background: @white;

        &Icon {
          background-color: @gray-4;
        }
      }
    }

    &Cell {
      &Button {
        height: auto;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        padding: 5px;
        padding-right: 10px;
        margin: 0 -5px;
        background: white;
        text-align: center;
        user-select: none;
        text-wrap: nowrap;
        user-select: none;
        cursor: pointer;
        position: relative;
        white-space: pre-wrap;

        &Description {
          width: 100%;
          font-size: 10px;
          font-weight: normal;
          color: gray;
          user-select: none;
        }

        .ActionArea {
          position: absolute;

          left: 5px;
          right: 10px;
          top: 0;
          bottom: 0;

          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          background-color: rgba(@black, 0.7);
          border-radius: @border-radius-base;
          opacity: 0;

          overflow: hidden;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &Item,
    &Item::selection {
      text-align: center;
      border-radius: @border-radius-base;
      border: 1px dashed @gray-4;

      margin: 0 5px;
      padding: 5px;
      font-size: 12px;
      min-width: 28px;
      height: 44px;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      max-lines: 3;
      background-color: transparent;
      color: @black;
      user-select: none;
      cursor: pointer;
    }

    &Selected {
      position: absolute;
      border-radius: 0;
      border: none;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;

      &Content {
        border-radius: @border-radius-base;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @primary-color;
        background-color: @white;
        border: 1px solid @primary-color;
        width: 100%;
        user-select: none;
        pointer-events: none;
      }
    }

    &Round {
      margin: 0 5px;
      font-size: 18px;
      font-weight: 1000;
      height: 44px;
      text-align: center;
      user-select: none;
      border-radius: 5px;
    }

    &preCompetitionPractice {
      background: #ffd8bf;
    }

    &rest {
      background: #91d5ff;
    }

    &preCompetitionAdjustment {
      background: #ffccc7;
    }

    &customization {
      background-color: #eaff8f;
    }

    &Footer {
      display: flex;
      flex-flow: row;
      gap: 20px;

      &Button {
        height: 44px !important;
        font-size: 12px;
        font-weight: 1000;
        padding: 5px;
        text-align: center;
        user-select: none;
        border-radius: 5px;
        margin-bottom: 20px;
      }
    }

    &Mask {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      pointer-events: none;
    }
  }
}

th:first-child,
td:first-child {
  position: sticky;
  left: 0;
  background-color: #ffffff;
  z-index: 1;
}

thead,
tbody {
  gap: 10px;
}

.ant-popover-message-title {
  padding: 0 !important;
}

.Segment {
  display: flex;
  flex-flow: row;
  gap: 0px;

  &Child {}

  &Popover {
    display: flex;
    flex-flow: column;
    gap: 15px;

    &Item {
      display: flex;
      flex-flow: row;
      gap: 5px;

      &Icon {
        background-color: @primary-color;
        color: @white;
        border-radius: 50%;
        padding: 5px;
        font-size: 12px;
        line-height: 12px;
      }
    }

    &Back {
      width: fit-content !important;
      padding: 0 !important;
    }

    &Form {
      // margin-top: 0px;
    }
  }
}

.SetProjectTargetPopover {
  &Project {
    padding: 10px;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    width: 500px;
    min-height: 370px;

    &StageButton {
      color: @white  !important;
      background-color: #91d5ff;
      border: none !important;
    }
  }

  &Target {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    width: 500px;
    min-height: 370px;
    margin: 10px 0;

    &Item {
      // 两个一行
      flex: 0 0 50%;
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 5px;

      &Image {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }

      &Title {
        font-size: 18px;
        font-weight: @font-weight-bold;
        color: @black;
      }
    }
  }
}

.TargetSettingTableItemBox {
  position: relative;
  width: 100%;
  height: 100%;

  &Text {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.TargetSettingTableItemRemoveButton.ant-btn {
  position: absolute;
  top: -2px;
  right: -2px;
  padding: 2px 4px;
  color: @red-color;
  z-index: 100;
  background-color: @gray-5;
}
@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';