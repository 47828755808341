@import '../../../../../../style/variables.less';

.AssociationPlayerListAddButton.ant-btn {
  width: fit-content;
  margin-right: 24px;
  border: 2px solid;
  border-radius: 7px;
  padding: 7px 16px;
  min-width: 146px;
  height: 42px;
  font-size: 16px;
  line-height: 24px;
  border-color: @primary-color;
  color: @primary-color;
  z-index: 1;
}

.AssociationPlayerListAddButtonText {
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  justify-items: center;
  align-items: center;
  justify-content: space-around;
}

.AssociationPlayerListSearchClubsFilter {
  min-width: 200px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';