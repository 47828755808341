.EnrollmentClubFormSetting {
  .EnrollmentDetailsOptionListFromItem.ant-form-item {
    margin-left: 0;
    margin-right: 0;
  }
}

.NestItemOptionNewModal,
.NestItemOptionDetailModal {
  .ant-modal-close {
    display: none;
  }
}

.NestItemOptionNewModalFormItem,
.NestItemOptionDetailModalFormItem {
  .ant-form-item-label {
    min-width: 80px;
  }

  .ant-input-number-group-wrapper {
    width: 100%;
  }
}

.NestItemOptionNewModalFormPrice.ant-form-item {
  margin-left: 20px;
}

.NestItemOptionNewModalPropertyType,
.NestItemOptionDetailModalPropertyType {
  max-width: 90px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';