@import '../../../../../../../../style/variables.less';

.TournamentDetailModalTitleItem {
  max-width: 320px;
}

.TournamentDetailModalEnrollmentItem {
  max-width: 320px;
}

.TournamentDetailModalBasicInfo {
  padding-left: 10px;
}

.TournamentDetailModalBasicInfoLink.ant-btn {
  padding: 0;
  text-decoration: underline;
}

.TournamentDetailModalBasicInfoLinkText {
  color: @red-color;
}

.TournamentDetailModalDemandedItem.ant-form-item {

  .ant-form-item-row {
    flex-direction: column;

    .ant-checkbox-group-item {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .ant-form-item-label {
      text-align: left;
    }
  }
}

.TournamentDetailModalAddNewEmployeeButton.ant-btn {
  margin-top: 8px;
  margin-bottom: 24px;
}

.TournamentDetailModalTargetItem.ant-form-item {
  margin-bottom: 0;
}

.TournamentDetailModalFooterButtonGroup {
  margin: 8px 0;
}

.TournamentDetailModalFooterButton.ant-btn {
  padding: 8px;
  height: 46px;
}

.CreateTournamentModalJudges {
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid @gray-4;
  padding: 0.5em;
  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;
  border-radius: @border-radius-base;
}
@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';