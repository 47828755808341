@import '../../../../style/variables.less';

.cancelButton.ant-btn {
  color: #4fc7e4;
  border: 1px solid #4fc7e4;
}


.StoreDetailButtons {
  margin-top: 36px;
  display: flex;
  flex-flow: wrap;
  gap: 10px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';