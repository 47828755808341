.dashboard {
  height: 100%;
}

.dashboardHeaderRight {
  position: relative;
}

.dashboardStoreListButton.ant-select {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 146px;
  height: 42px;
  z-index: 1;
  opacity: 0;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: inherit;
  }
}

.dashboardStoreItem {
  width: 100%;
}

.dashboardSubContainer {
  padding: 20px 24px 10px;
}

.dashboardSubtitle {
  margin-bottom: 24px;
}

.dashboardReportListContainer {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.dashboardCardPaidTrendContainer {
  width: 75%;
}

.dashboardCardPaidChartContainer.ant-select {
  margin-bottom: 24px;
}

.dashboardTodayVisitorContainer {
  border-left: 1px solid #d9d9d9;
  width: 25%;
  min-width: 250px;
}

.dashboardTodayVisitorContent {
  text-align: center;

  .ant-progress {
    padding: 45px 0 25px;
  }
}

.dashboardTodayVisitorCount {
  font-size: 3.5rem;
  font-weight: bold;
}

.dashboardTodayVisitorPrompt {
  font-size: 0.85714rem;
}

@media (max-width: 1240px) {
  .dashboardCardPaidTrendContainer {
    width: 50%;
  }

  .dashboardTodayVisitorContainer {
    min-width: 0;
  }
}

@media (max-width: 960px) {
  .ant-layout .dashboardMembersInfoContainer {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }

  .dashboardCardPaidTrendContainer,
  .dashboardTodayVisitorContainer {
    width: 100%;
  }
}

.dashboard .insertButton.ant-btn {
  padding: 0 16px;

  .anticon {
    height: 1em;
    vertical-align: middle;
  }

  span {
    // antd Select componet tag without classname
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 1em;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';