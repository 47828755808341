@import '../../style/variables.less';

.appLayout {
  &__content.ant-layout-content {
    min-height: 100%;
  }
}

.headerUserInfo {
  display: inline-flex;
}

.headerUserExpiredDays {
  font-size: 1.142857rem;
  color: @pink-color;
  font-weight: bold;
}

.headerUserName {
  margin: 0 8px;
  font-size: 1.142857rem;
  font-weight: bold;
}

.headerIcon {
  margin: 0 8px;
  font-size: 1.714285rem;

  &.anticon-bell {
    color: @black;
  }
}

.headerAvatar.ant-avatar {
  margin: 0 8px;
}

.appLayout__content {
  height: 100%;
  overflow-y: auto;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';