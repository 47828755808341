@import '../../../../../style/variables.less';

.SubscriptionHistory {
  border: 1px solid @gray-5;
  border-radius: @border-radius-base-lg;
}

.SubscriptionHistoryTable {
  .ant-table-cell {
    background-color: @white;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';