.Subscription {
  padding: 0 13px;
  height: 100%;
}

.SubscriptionPayment {
  margin: 0 13px;
  width: 30%;
}

.SubscriptionAfterPayment {
  margin: 0 13px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';