@import '../../../../../style/variables.less';

.SubscriptionPlan.ant-collapse {
  margin: 16px 0;

  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 20px 20px 14px;
  }
}

.ant-collapse > .SubscriptionPlanPanel.ant-collapse-item,
.ant-collapse > .SubscriptionPlanPanel.ant-collapse-item:last-child {
  border: 1px solid @gray-4;
  border-radius: @border-radius-base-lg;
}

.SubscriptionPlanHeaderDescriptionList {
  margin-bottom: 0;
}

.SubscriptionPlanRadio {
  .ant-radio-inner {
    width: 24px;
    height: 24px;
  }

  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.75);
  }
}

.SubscriptionPlanTitle {
  font-size: 1.142857rem;
  line-height: 24px;
  color: @primary-color;
  font-weight: @font-weight-bolder;
}

.SubscriptionPlanDescription {
  margin-top: 5px;
  margin-bottom: 0;
  max-width: 200px;
  font-size: 0.714285rem;
  color: @gray-2;
  opacity: 0.75;
}

.SubscriptionPlanPrice {
  font-size: 1.714285rem;
  line-height: 1;
  font-weight: @font-weight-bolder;
}

.SubscriptionPlanUnit {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 0.857142rem;
  font-weight: @font-weight-bold;
  color: @gray-2;
}

.SubscriptionPlanFunctionContainer {
  margin-top: -32px;
  padding: 20px 38px;
}

.ant-list .SubscriptionPlanFunction.ant-list-item {
  padding: 4px 0;
  border-bottom: 0;
}

.SubscriptionPlanFunctionMeta {
  .ant-list-item-meta-avatar {
    line-height: 1;
  }

  .ant-list-item-meta-description {
    font-size: 0.714285rem;
    line-height: 1.16;
  }
}

.SubscriptionPlanStore {
  margin: 0 -16px -12px;
  padding: 10px 20px;
  border-top: 1px dashed @gray-4;
}

.SubscriptionPlanStoreIcon {
  margin: 2px 6px;
  font-size: 1.2857142857rem;
}

.SubscriptionPlanStoreLabelContainer {
  margin-left: 6px;
}

.SubscriptionPlanStoreLabel {
  margin: 0;
  font-size: 1.142857rem;
  line-height: 1.4;
  color: @gray-2;
  opacity: 0.75;
}

.SubscriptionPlanStoreHint {
  margin: 0;
  font-size: 0.857142rem;
  line-height: 1;
  color: @gray-3;
  opacity: 0.75;
}

.SubscriptionPlanStoreInput {
  width: 130px;

  .ant-input-number-group-addon {
    padding: 0;
  }

  .ant-input-number-input {
    padding: 0;
    text-align: center;
  }
}

.SubscriptionPlanStoreAddonButton.ant-btn {
  padding-left: 10px;
  padding-right: 10px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';