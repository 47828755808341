@import '../../../../../../style/variables.less';

.AssociationHistoryModalSelectContainer {
  margin-bottom: 16px;
  width: 100%;
}

.AssociationHistoryModalSelectLabel,
.AssociationHistoryModalSelect {
  width: 100%;
  max-width: 250px;
}

.AssociationHistoryModalSelect {
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
    min-height: 32px;
  }

  .ant-select-selection-item {
    overflow: visible;
    text-overflow: initial;
    white-space: initial;
    word-break: break-all;
  }
}

.AssociationHistoryModalSelectDropdown {
  .ant-select-item-option-content {
    text-overflow: initial;
    white-space: initial;
    word-break: break-all;
  }
}

.AssociationHistoryModalSelectLabel {
  padding: 8px 0;
  &::before {
    content: '* ';
    color: @red-color;
  }
}

.AssociationHistoryModalGhostButton {
  margin: 8px;
  min-width: 158px;
}

.AssociationHistoryModalUpload {
  position: relative;

  &.ant-upload-picture-card-wrapper {
    display: inline-flex;
    width: 60%;
  }

  .ant-upload-list,
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    overflow: hidden;
  }

  .ant-upload-list-picture-card
    .ant-upload-list-item:hover
    .ant-upload-list-item-info::before {
    left: 0;
  }

  .ant-upload-list-picture-card-container {
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    opacity: 0;
  }

  .ant-upload-list-item-actions > a {
    display: none;
  }
}

.AssociationHistoryModalUploadImageButton.ant-btn {
  width: 100%;
  height: 100%;

  &.hidden {
    opacity: 0;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';