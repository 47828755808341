@import '../../../../../style/variables.less';

.SubscriptionBill {
  margin: 16px auto;
}

.SubscriptionBillPaymentOptionsTitle {
  font-size: 1.142857rem;
  font-weight: @font-weight-bolder;
}

.SubscriptionBillPaymentOptionList {
  margin: 0;
}

.SubscriptionBillPaymentOption {
  margin: 20px 0;
}

.SubscriptionBillList {
  margin: 0;
}

.SubscriptionBillListSkeleton {
  margin-bottom: 16px;
}

.SubscriptionBillItem {
  padding: 8px 0;
  border-top: 1px dashed @gray-4;
  width: 100%;
  min-height: 41px;

  .ant-skeleton-active .ant-skeleton-paragraph > li::after {
    height: 100%;
  }
}

.SubscriptionBillPrice {
  font-size: 1.142857rem;
}

.SubscriptionBillLabelMinus {
  color: @green-color;
}

.SubscriptionBillLabelCovered {
  text-decoration: line-through;
}

.SubscriptionBillTooltip {
  margin: 0 4px;
}

.SubscriptionBillPriceCovered {
  text-decoration: line-through;
}

.SubscriptionBillTotalContainer {
  padding: 12px 0;
  border-top: 1px dashed @gray-4;

  .ant-skeleton-paragraph {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: 3.14rem;
  }
}

.SubscriptionBillTotalLabel {
  font-size: 1.142857rem;
  line-height: 1.5;
  color: @gray-1;
  font-weight: @font-weight-bolder;
}

.SubscriptionBillTotal {
  font-size: 2.285714rem;
  line-height: 1.4;
  color: @gray-1;
  font-weight: @font-weight-bolder;
}

.SubscriptionBillPaymentConfirmModal {
  .ant-modal-content {
    margin: auto;
    max-width: 420px;
  }

  .ant-modal-confirm-content {
    text-align: center;
  }
}

.SubscriptionBillPaymentConfirmModalQrCode,
.SubscriptionBillPaymentConfirmModalQrCodePlaceholder {
  margin: 16px auto;
}

.SubscriptionBillPaymentConfirmModalQrCodePlaceholder {
  width: 300px;
  height: 300px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';