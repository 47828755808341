.EnrollmentClubsButtonGroup {
  padding: 16px 24px;
}

.EnrollmentClubsExportButton.ant-btn {
  border: 2px solid;
  height: 42px;
  font-size: 16px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';