@import '../../../../style/variables.less';

.MarketFunctionCard.ant-card {
  border: 1px solid @gray-5;
  background-color: @gray-5;
  overflow: hidden;

  .ant-card-cover {
    position: relative;
    margin: 0;
    padding: 50% 0;
  }

  .ant-image {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    width: 100%;
    height: 100%;
    background-color: @white;
  }

  .ant-card-body {
    padding: 0;
  }
}

.MarketFunctionCardImage.ant-image-img {
  background-color: @white;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.MarketFunctionCardTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 16px;
}

.MarketFunctionCardTitle {
  font-size: 1rem;
  color: @gray-1;
  font-weight: @font-weight-bold;
}

.MarketFunctionCardButton.ant-btn,
.MarketFunctionCardButton.ant-btn:hover {
  height: auto;
  line-height: 1em;
  background-color: #e6fffb;
  color: @primary-color;
  border-color: @primary-color;
}

.MarketFunctionCardTag.ant-tag-success {
  padding: 1px 12px;
  margin-right: 0;
  min-width: 63px;
  font-size: 1rem;
  border-radius: 4px;
}

.MarketFunctionCardDescription {
  margin: 12px 16px;
  font-size: 0.85714rem;
  line-height: 1.6;
  color: @gray-2;
  font-weight: 400;

  -webkit-line-clamp: 2;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';