@import '../../../../../../../../style/variables.less';

.PlayerDetail {
  .ant-input-group.ant-input-group-compact {
    display: flex;
  }
}

.PlayerDetailTransferClubButton {
  border: 0;
  padding: 0 4px;
  color: @primary-color;
  background-color: transparent;
  cursor: pointer;
}

.PlayerDetailCancelTransferClubButton {
  border: 0;
  padding: 0 4px;
  color: @red-color;
  background-color: transparent;
  cursor: pointer;
}

.PlayerDetailDeleteButton.ant-btn {
  > span + .anticon {
    margin-left: 2px;
  }
}

.PlayerDetailFooterPromptText {
  font-size: 0.857142rem;
  color: @gray-3;
}

.PlayerDetailFooterPromptTextRequired {
  color: @red-color;
}

.PlayerDetailClubLinkButton.ant-btn {
  padding: 0;
  height: auto;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';