@import '../../../style/variables.less';

.inputDiv {
  margin-bottom: 26px;
}

.button_container_col {
  flex-wrap: wrap;
  display: inline-flex;
}
.button_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: bold;
}

.settingsQRCodeButton.ant-btn {
  margin-right: 23px;
  line-height: 24px;
  width: 160px;
  height: 42px;
  padding: 7px 16px;
  border: 2px solid;
  border-radius: 7px;
  border-color: @primary-color;
  color: @primary-color;
}

.settingsQRCodeButtonText {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
}

.settingsInputsContainer {
  margin: 0 32px;
  max-width: 400px;
}

.settingsHeaderContainer {
  margin: 28px 0;
  line-height: 30px;
}

.settingsLogoUploader.ant-upload-drag,
.settingsBannerUploader.ant-upload-drag {
  margin-top: 8px;

  &.hidden {
    display: none;
  }
}

.settingsUploadIcon {
  margin-bottom: 8px;
  font-size: 30px;

  > svg {
    fill: @primary-color;
  }
}

.settingsUploadText {
  font-size: 12px;
}

.settingMiniProgramLiveStream {
  padding: 0;
}

.settingMiniProgramLiveStreamTitle {
  font-size: 12px;
}

.settingMiniProgramLiveStreamId {
  margin-top: 20px;
}

.settingMiniProgramLiveStreamIdTitle {
  display: inline-block;
  font-size: 12px;
  padding-bottom: 8px;
}

.settingFooter {
  margin-top: 36px;
  margin-bottom: 36px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';