@import '../../../../style/variables.less';

.PlayerDetailsContainer {
  padding: 0 24px;
}

.PlayerDetailsInfo {
  max-width: 448px;
}

.cardContainer {
  margin: 5px 32px;
  padding: 8px 8px;
  max-width: 400px;
  border: 1px solid @gray-4;
  border-radius: @border-radius-base;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';