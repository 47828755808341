.EnrollmentDetailsAttributePriceItem.ant-form-item {
  margin-left: 70px;

  .ant-row.ant-form-item-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    -ms-flex-align: start;
    -webkit-align-items: start;
    align-items: start;
    flex-wrap: nowrap;
  }

  .ant-col.ant-form-item-label {
    -ms-flex-shrink: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    padding: 0;
    line-height: 30px;
  }

  .ant-col.ant-form-item-control {
    max-width: 180px;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';