@import '../../../../style/variables.less';

.RenewalAgreementModal.ant-modal {
  max-width: 1440px;
}

.RenewalAgreementModalCustomerAgreement {
  ol,
  ul {
    padding-left: 36px;
  }

  table {
    table-layout: fixed;
    margin-bottom: 20px;
  }

  th,
  td {
    padding: 15px;
    min-width: 200px;
  }

  table,
  th,
  td {
    border: 1px solid @gray-3;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';