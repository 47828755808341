@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './cutomize-theme.less';

* {
  margin: 0;
  padding: 0;
  font-weight: normal;
  box-sizing: border-box;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulate;
}

html {
  background: @white;
  font-size: @font-size-base;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: @font-weight-bold;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-size: @font-size-base;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: @text-color;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  &__shrink-fixed {
    -ms-flex-shrink: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }

  &__fluid-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    flex: 1;
  }

  &__wrap {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }
}

.flex-middle {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-top {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.flex-bottom {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.flex-stretch {
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}

.flex-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.flex-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.flex-end {
  -ms-flex-pack: justify;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

#root {
  height: 100%;
}

.primary-color {
  color: @primary-color;
}

.text {
  &__omit {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    text-overflow: ellipsis;
  }
}

// TODO: Spacing mixins

// Cover Styles
.ant-input-affix-wrapper {
  border-width: 1.5px;
}

.ant-btn-round {
  border-radius: 6px;
}

// Form
.formItem .ant-form-item-label > label,
.formItemLabel {
  font-size: 12px;
}

.forItemContent {
  width: 100%;
}

.ant-form-item-extra {
  margin: 4px 0;
  font-size: 12px;
}

.insertButton.ant-btn {
  margin-right: 24px;
  border: 2px solid;
  border-radius: 7px;
  padding: 7px 16px;
  min-width: 146px;
  height: 42px;
  font-size: 16px;
  border-color: @primary-color;
  color: @primary-color;
  z-index: 1;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';