@import '../../../../style/variables.less';

.addButton.ant-btn {
  margin-right: 24px;
  border-width: 2px;
  min-width: 146px;
  height: 42px;
  font-size: 16px;
}

.PlayerListFilterForm {
  padding: 24px;
  gap: 16px;
}

.PlayerListSearchItem {
  width: 100%;
  max-width: 300px;
}

@media (max-width: 768px) {
  .PlayerListFilterForm {
    padding: 16px;
  }

  .PlayerListSearchItem {
    max-width: none;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';