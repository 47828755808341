@import '../../../style/variables.less';

.BreadcrumbRouter.ant-breadcrumb {
  padding: 30px 24px;

  .ant-breadcrumb-link {
    display: inline-flex;
  }

  > ol,
  > ol > li {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}

.ant-breadcrumb .BreadcrumbRouterLink,
.BreadcrumbRouterLastTitle.ant-typography {
  font-size: 24px;
  font-weight: bold;
}

.ant-breadcrumb .BreadcrumbRouterLink {
  color: @primary-color;
}

.BreadcrumbRouterLastTitle.ant-typography {
  margin: 0;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';