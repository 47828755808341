@import '../../../../../../style/variables.less';

.NewTournamentButtonContainer {
  padding: 16px 24px;
}

.NewTournamentButton.ant-btn {
  border: 2px solid;
  border-radius: 7px;
  padding: 7px 16px;
  min-width: 146px;
  height: 42px;
  font-size: 16px;
  line-height: 24px;
  border-color: @primary-color;
  color: @primary-color;
  z-index: 1;
}

.TournamentListJudgeButton.ant-btn {
  margin: 4px;
}

.TournamentListJudgeButtonMore {

  >span:last-child {
    margin-left: 4px !important;
  }
}

.TournamentListControlButton.ant-btn {
  padding: 0;
}

@media (max-width: 760px) {
  .NewTournamentButton.ant-btn {
    padding: 0;
    border: 0;
    min-width: 0;
  }

  .NewTournamentButtonOutlinedIcon.anticon-plus-circle {
    font-size: 24px;
  }

  .NewTournamentButton>span.NewTournamentButtonText {
    display: none;
  }
}
@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';