@import '../../../../style/variables.less';

.FunctionCard.ant-card {
  border: 1px solid @gray-5;
  background-color: @gray-5;
  overflow: hidden;

  .ant-card-cover {
    position: relative;
    margin: 0;
    padding: 50% 0;
  }

  .ant-image {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    width: 100%;
    height: 100%;
    background-color: @white;
  }

  .ant-card-body {
    padding: 0;
  }
}

.FunctionCardImage.ant-image-img {
  background-color: @white;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.FunctionCardTitleContainer {
  flex-direction: row;
  margin: 10px 16px;
}

.FunctionCardTitle {
  font-size: 1rem;
  color: @gray-1;
  font-weight: @font-weight-bold;
}

.FunctionCardButton.ant-btn,
.FunctionCardButton.ant-btn:hover {
  height: auto;
  line-height: 1em;

  &.default {
    background-color: #e6fffb;
    color: @primary-color;
    border-color: @primary-color;
  }

  &.trial {
    background-color: #f9f0ff;
    color: @purple-color;
    border-color: @purple-color-1;
  }
}

.FunctionCardTag.ant-tag-success {
  padding: 1px 12px;
  margin-right: 0;
  min-width: 63px;
  font-size: 1rem;
  border-radius: 4px;
}

.FunctionCardDescription {
  margin: 12px 16px;
  font-size: 0.85714rem;
  min-height: 38px;
  line-height: 1.6;
  color: @gray-2;
  font-weight: 400;

  -webkit-line-clamp: 2;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';