@import '../../../../../../style/variables.less';

.NewEnrollmentButtonContainer {
  padding: 16px 24px;
}

.ClubAuthenticationAddButton.ant-btn {
  width: fit-content;
  margin-right: 24px;
  border: 2px solid;
  border-radius: 7px;
  padding: 7px 16px;
  min-width: 146px;
  height: 42px;
  font-size: 16px;
  line-height: 24px;
  border-color: @primary-color;
  color: @primary-color;
  z-index: 1;
}

.ClubAuthenticationAddButtonText {
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  justify-items: center;
  align-items: center;
  justify-content: space-around;
}

.ClubAuthenticationTransferContainer {
  padding: 0 8px;
}

.ClubAuthenticationTransferIcon.anticon svg {
  font-size: 24px;
  color: #faad14;
}

.ClubAuthenticationTransferContent {
  margin-left: 12px;
  margin-bottom: 0;
}

.ClubAuthenticationTransferDescription {
  padding: 12px 0 0;
}

.ClubAuthenticationTransferFormItem.ant-form-item {
  margin-top: 16px;
  margin-bottom: 0;
}

@media (max-width: 760px) {
  .NewEnrollmentButton.ant-btn {
    padding: 0;
    border: 0;
    min-width: 0;
  }

  .NewEnrollmentButtonOutlinedIcon.anticon-plus-circle {
    font-size: 24px;
  }

  .NewEnrollmentButton > span.NewEnrollmentButtonText {
    display: none;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';