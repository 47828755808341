@import '../../../../style/variables.less';

.ListTable {
  background-color: @gray-5;
}

.ant-list-sm .ListTableItem.ant-list-item {
  padding: 0;
  margin-bottom: 16px;
}

.ListTableItemCard {
  border-radius: 0;

  .ant-card-body {
    padding: 16px;
  }
}

.ListTableItemCardItem {
  padding: 12px;
  border-radius: @border-radius-base;
}

.ListTableItemCardItem:nth-child(odd) {
  background-color: @gray-5;
}

.ListTableCardHeader {
  padding-bottom: 12px;
  border-bottom: 1px solid @gray-5;
}

.ListTableCardHeaderLeft {
  font-size: 0.85714rem;
  color: @gray-3;
}

.ListTableItemCardItemTitle,
.ListTableItemCardItemDescription {
  margin: 0;
}

.ListTableItemCardItemTitle {
  color: @gray-3;
}

.ListTableItemCardItemDescription {
  font-size: 0.85714rem;
  font-weight: bold;
}

.ListTableCardContentHeader {
  padding-bottom: 24px;
}

.ListTableCardHeader + .ListTableCardContentHeader {
  padding: 24px 0;
}

.ListTableCardContentHeaderLeft,
.ListTableCardContentHeaderRight {
  margin: 0;
}

.ListTableCardContentHeaderRightIcon.anticon-right {
  font-size: 16px;

  svg {
    fill: @gray-4;
  }
}

.ListTablePagination.ant-pagination {
  text-align: right;
  padding-bottom: 24px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';