.AssociationFunctionsContainer {
  padding: 0 18px;
}

.AssociationFunctionsCardContainer {
  width: 33.333333%;
  max-width: 230px;
  padding: 18px;
}

@media (max-width: 768px) {
  .AssociationFunctionsContainer {
    padding: 0 9px;
  }

  .AssociationFunctionsCardContainer {
    padding: 5px;
    margin: 0;
    max-width: none;
  }
}

@media (max-width: 580px) {
  .AssociationFunctionsCardContainer {
    width: 50%;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';