@import '../../../../style/variables.less';

.ContentTablePagination.ant-pagination {
  margin: 16px 24px;
}

.ContentTableRowClickable {
  cursor: pointer;
  color: @primary-color;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';