@import '../../../../style/variables.less';

.settingsInputsContainer {
  margin: 0 32px;
  max-width: 400px;

  .bf-content.disabled {
    position: relative;
    opacity: 0.5;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }
}

.inputDiv {
  margin-bottom: 26px;
}

.richTextInput {
  margin-bottom: 0;
}

.bf-container * {
  font-weight: inherit;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';