.employeeAdminModalResult.ant-result {
  padding: 24px 0;
}

.employeeAdminModalSuccessText {
  margin-bottom: 0;
}

.employeeAdminModalDropdown {
  width: 100%;
}

.employeeAdminModalDropdownSpace {
  width: 100%;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';