@import '../../../../../../style/variables.less';

.EnrollmentDetailsRemoveIcon {
  height: 18px;
  font-size: 18px;
}

.EnrollmentDetailsAttributeAllowCross .ant-row.ant-form-item-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 6px;

  .ant-form-item-label {
    padding-bottom: 0;
    margin: 0 10px;
  }

  .ant-form-item-control {
    width: auto;
  }
}

.EnrollmentDetailsCategorySwitchText {
  margin: 0 4px;
}

.EnrollmentDetailsCategoryList {
  margin-bottom: 24px;
  list-style: none;
}

.EnrollmentDetailsCategoryItem {
  margin: 0 20px;
}

.EnrollmentDetailsCategoryAddFieldItem {
  margin: 0 16px 24px 0;
  max-width: 400px;
}

.EnrollmentDetailsCategoryNameFormItem,
.EnrollmentDetailsCategoryOption {
  .ant-row.ant-form-item-row {
    flex-direction: row;
  }

  .ant-row .ant-form-item-label {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: start;
    -webkit-align-items: start;
    align-items: start;
    line-height: 32px;
    padding-bottom: 0;
  }

  .ant-form-item-control {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    flex: 1;
  }
}

.EnrollmentDetailsCategoryNameFormItem {
  width: 210px;
}

.EnrollmentDetailsCategoryOptionContent {
  gap: 24px;
}

.EnrollmentDetailsCategoryOptionButton.ant-btn {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
}

.EnrollmentDetailsOptionListFromItem.ant-form-item {
  margin-left: 10px;
  margin-right: 10px;
}

.EnrollmentDetailsOptionList {
  padding: 1px;
  border: 1px solid @primary-color;
  background-color: @white;
  list-style: none;
  border-radius: 6px;

  &.disabled {
    background-color: @gray-5;
    border-color: @gray-4;
  }
}

.EnrollmentDetailsCategoryIsOpenOrYouthGroupTooltipContainer {
  margin-left: -6px;
  margin-right: 10px;
  margin-bottom: 24px;
}

.EnrollmentDetailsCategoryIsOpenOrYouthGroupTooltip {
  padding-left: 20px;
  list-style: disc;
}

.EnrollmentDetailsOptionItemButton.ant-btn {
  padding: 2px;
  height: auto;

  .ant-tag {
    margin-right: 0;
  }
}

.EnrollmentDetailsOptionItemText {
  font-size: 0.85714rem;
}

.EnrollmentDetailsOptionAddFieldItemButton.ant-btn {
  margin: 0 2px;
  border: 2px solid @primary-color;
  color: @primary-color;
  background-color: #e6f7ff;
}

.EnrollmentDetailsCategoryRemoveButton.ant-btn {
  margin-bottom: 24px;
  height: 20px;
  line-height: 20px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';