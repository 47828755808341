@import '../../../../style/variables.less';

.StatisticCard {
  padding: 24px 20px;
  margin: 12px 0;
  border: 1px solid @gray-4;
  border-radius: 6px;

  .ant-statistic {
    color: inherit;
  }

  .ant-statistic-title {
    font-size: 1rem;
    color: inherit;
  }

  .ant-statistic-content-value-int {
    font-size: 2.714285rem;
    font-weight: 600;
  }

  .ant-statistic-content-suffix {
    font-size: 1rem;
    color: #7cb305;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';