@import '../../style/variables.less';

.tournamentDetailContainer {
  padding: 0 24px;
  max-width: 448px;
}

.tournamentEnableSwitchDescription {
  margin-left: 10px;
}

.tournamentDetailEmployeeSelector {
  min-width: 100px;
}

.buttonSection {
  margin-bottom: 20px;
}

.rc-virtual-list-holder {
  scroll-behavior: unset;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';