@import '../../../../style/variables.less';

.register {
  min-height: 768px;
}

.register,
.registerContentContainer {
  height: 100%;
  overflow: hidden;
}

.registerImageContainer {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: @primary-color;
  background-image: url(../../../../images/entrance-background.png);
  background-position: bottom;
  background-repeat: repeat-x;
  overflow: hidden;
}

.registerImage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  margin: auto;
  max-width: 100%;
  max-height: 90%;
}

.logoImageContainer {
  display: none;
}

.logoImage {
  width: 60px;
}

.registerContent {
  padding: 0 12px;
  max-width: 500px;
  margin: auto;
}

.registerTitle {
  margin-bottom: 0;
  font-size: 2.142857rem;
  font-weight: bold;
}

.registerTextDivider.ant-divider-horizontal {
  margin-top: 12px;
  margin-bottom: 12px;
}

.registerForm.ant-form-vertical {
  padding-top: 8px;

  .ant-form-item + .ant-form-item {
    margin-top: 20px;
  }
}

.registerOtp .ant-space-item:last-child {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}

.registerOtp .ant-space-item:first-child {
  width: 58%;
}

.registerButton.ant-form-item {
  margin-top: 20px;
}

.registerBusinessSelect.ant-select-in-form-item {
  width: 100%;
  height: 62px;
}

.registerAccountInfo .ant-space-item {
  &:first-child {
    width: 65%;
  }

  &:last-child {
    width: 35%;
  }
}

.registerBusinessSelect.ant-select-single.ant-select-lg:not(
    .ant-select-customize-input
  ) {
  .ant-select-selector {
    border-width: 1.5px;
    height: inherit;

    .ant-select-selection-item {
      line-height: 59px;
    }
  }
}

.registerProtocolDisabledIcon {
  opacity: 0.35;
}

.registerProtocolText,
.registerProtocolButton.ant-btn {
  font-size: 0.85714rem;
}

.registerProtocolText {
  margin: 0 4px;
}

.registerProtocolButton.ant-btn {
  padding: 0;
  margin: 0;

  > span {
    text-decoration: underline;
  }
}

.registerProtocolModal.ant-modal {
  max-width: 800px;
}

.registerProtocolTermsContent {
  max-height: 360px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /*Handle*/
  &::-webkit-scrollbar-thumb {
    background-color: @gray-4;
    border-radius: 10px;
  }
}

@media (max-width: 1000px) {
  .registerImageContainer {
    display: none;
  }

  .logoImageContainer {
    display: flex;
    justify-content: center;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';