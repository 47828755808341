.ContentDetailTitle.ant-typography {
  -ms-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  padding: 30px 24px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .ContentDetailTitle.ant-typography {
    padding: 30px 16px;
    font-size: 1.142857rem;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';