@import '../../../../style/variables.less';

.employeeDetailFormContainer {
  padding: 0 24px;
  max-width: 448px;
}

.outlineButton.ant-btn {
  color: @primary-color;
  border: 1px solid @primary-color;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';