@import '../../../../style/variables.less';

.forgetPasswordSuccessResult {
  height: 100%;
}

.forgetPassword {
  min-height: 768px;
}

.forgetPassword,
.forgetPasswordContentContainer {
  height: 100%;
  overflow: hidden;
}

.forgetPasswordImageContainer {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: @primary-color;
  background-image: url(../../../../images/entrance-background.png);
  background-position: bottom;
  background-repeat: repeat-x;
  overflow: hidden;
}

.forgetPasswordImage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  margin: auto;
  max-width: 100%;
  max-height: 90%;
}

.logoImageContainer {
  display: none;
}

.logoImage {
  width: 60px;
}

.forgetPasswordContent {
  padding: 0 12px;
  max-width: 500px;
  margin: auto;
}

.forgetPasswordTitle {
  margin-bottom: 0;
  font-size: 2.142857rem;
  font-weight: bold;
}

.forgetPasswordTextDivider.ant-divider-horizontal {
  margin-top: 12px;
  margin-bottom: 12px;
}

.forgetPasswordForm.ant-form-vertical {
  padding-top: 8px;

  .ant-form-item + .ant-form-item {
    margin-top: 20px;
  }
}

.forgetPasswordOtp .ant-space-item:last-child {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}

.forgetPasswordOtp .ant-space-item:first-child {
  width: 58%;
}

.forgetPasswordBackLogin {
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .forgetPasswordImageContainer {
    display: none;
  }

  .logoImageContainer {
    display: block;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';