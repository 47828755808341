@import '../../../../../style/variables.less';

.SubscriptionInfoText {
  font-size: 1.142857rem;
  font-weight: @font-weight-bolder;
}

.SubscriptionInfoCurrentPlan {
  margin: 0;
  min-height: 26px;
  color: @gray-2;
}

.SubscriptionInfoExpiredDate::before {
  content: '';
  display: inline-flex;
  margin: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: @gray-2;
}

.SubscriptionInfoExpiredDate,
.SubscriptionInfoExpiredDateLabel {
  color: @pink-color;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';