@import '../../../../../../../../style/variables.less';

.CreateTournamentModalTitleItem {
  max-width: 320px;
}

.CreateTournamentModalAddressItem {
  max-width: 320px;
}

.CreateTournamentModalEnrollmentItem {
  max-width: 320px;
}

.CreateTournamentModalBasicInfo {
  padding-left: 10px;
}

.CreateTournamentModalBasicInfoLink.ant-btn {
  padding: 0;
  text-decoration: underline;
}

.CreateTournamentModalBasicInfoLinkText {
  color: @red-color;
}

.CreateTournamentModalDemandedItem.ant-form-item {
  margin-bottom: 0;

  .ant-form-item-row {
    flex-direction: column;

    .ant-checkbox-group-item {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .ant-form-item-label {
      text-align: left;
    }
  }
}

.CreateTournamentModalAddNewEmployeeButton.ant-btn {
  margin-top: 8px;
  margin-bottom: 24px;
}

.CreateTournamentModalTargetItem.ant-form-item {
  margin-bottom: 0;
}

.CreateTournamentModalJudges {
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid @gray-4;
  padding: 0.5em;
  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;
  border-radius: @border-radius-base;
}
@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';