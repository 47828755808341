@import '../../../../style/variables.less';

.FunctionsContainer {
  padding: 0 18px;
}

.FunctionsCardContainer {
  width: 33.333333%;
  max-width: 230px;
  padding: 18px;
}

.FunctionsTrialSubscriptionPromptModal {
  .ant-modal-content {
    padding-top: 64px;
  }

  .ant-modal-footer {
    padding-bottom: 64px;
    border-top: 0;
  }
}

.FunctionsTrialSubscriptionText {
  margin-bottom: 0;
  max-width: 560px;
  line-height: 1.4;
  font-size: 1.714285rem;
  color: @black;
  font-weight: bold;
}

.FunctionsTrialSubscriptionButton {
  padding: 24px 0;
  margin: auto;
  max-width: 414px;
}

.FunctionsTrialSubscriptionCustomerServiceImage {
  margin: 50px auto;
  max-width: 153px;
}

.FunctionsPromptText {
  text-indent: 2em;
}

@media (max-width: 768px) {
  .FunctionsContainer {
    padding: 0 9px;
  }

  .FunctionsCardContainer {
    padding: 5px;
    margin: 0;
    max-width: none;
  }
}

@media (max-width: 580px) {
  .FunctionsCardContainer {
    width: 50%;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';