@import '../../../../../../style/variables.less';

.cancelButton.ant-btn {
  color: #4fc7e4;
  border: 1px solid #4fc7e4;
}

.cardContainer {
  margin: 5px 32px;
  padding: 8px 8px;
  max-width: 400px;
  border: 1px solid @gray-4;
  border-radius: @border-radius-base;
}

.InputsContainer {
  max-width: 400px;
}

.row-button-pendding.ant-btn {
  font-size: 14px;
  font-weight: 600;
  width: 73px;
  height: 24px;
  border-radius: 4px;
  padding: 1px 8px;
  background: #eaf0f7;
  color: #6294ef;
  border: 1px solid #89bcfe;
  border-radius: 4px;
}

.row-button-pendding.ant-btn:hover {
  background: #dfebfa;
  color: #5b8de9;
  border: 1px solid #84b9fe;
}

.row-button-enable.ant-btn {
  font-size: 14px;
  font-weight: 600;
  width: 69px;
  height: 24px;
  border-radius: 4px;
  padding: 1px 8px;
  background: #f6ffed;
  color: #52c41a;
  border: 1px solid #b7eb8f;
  border-radius: 4px;
}

.row-button-enable.ant-btn:hover {
  background: #fbfff6;
  color: #80d754;
  border: 1px solid #c8f4a5;
}

.AssociationPlayersImportNotice {
  display: flex;
  flex-direction: row;
}

.AssociationPlayersImport {
  .ant-upload-list-item {
    display: none;
  }
}

.notice_title {
  width: 75px;
}

.notice_content {
  width: 400px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';