@import '../../../../../../../../style/variables.less';

.JudgesSelectionModalContainer {
  display: flex;
  flex-flow: column;
  gap: 10px;

  &Top {
    max-height: 100px;
    overflow-y: scroll;
    border: 1px solid @gray-4;
    padding: 0.5em;
    display: flex;
    flex-flow: row wrap;
    gap: 0.5em;
    border-radius: @border-radius-base;
  }

  &Bottom {
    display: flex;
    flex-flow: column;
    gap: 10px;

    &Group {
      max-height: 400px;
      overflow-y: scroll;
      display: flex;
      flex-flow: wrap;

      &Item {
        width: 32%;
        margin: 6px 0 !important;
        display: flex;
        align-items: center !important;
        user-select: none;

        &Right {
          width: 100%;
          white-space: normal;
          word-wrap: anywhere;
          overflow-wrap: anywhere;

          &Title {
            font-size: 16px;
            font-weight: @font-weight-bold;
            color: @black;
          }

          &Subtitle {
            font-size: 12px;
            color: @gray-2;
          }
        }
      }
    }
  }
}
@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';