@import '../../../../style/variables.less';

.login {
  min-height: 768px;
}

.login,
.loginContentContainer {
  height: 100%;
  overflow: hidden;
}

.loginImageContainer {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: @primary-color;
  background-image: url(../../../../images/entrance-background.png);
  background-position: bottom;
  background-repeat: repeat-x;
  overflow: hidden;
}

.loginImage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  margin: auto;
  max-width: 100%;
  max-height: 90%;
}

.logoImageContainer {
  display: none;
}

.logoImage {
  width: 60px;
}

.loginContent {
  padding: 0 12px;
  max-width: 500px;
  margin: auto;
}

.loginTitle {
  margin-bottom: 0;
  font-size: 2.142857rem;
  font-weight: bold;
}

.loginDescription {
  margin-bottom: 0;
  font-size: 1.42857rem;
}

.loginTextDivider.ant-divider-horizontal {
  margin-top: 12px;
  margin-bottom: 12px;
}

.loginForm.ant-form-vertical {
  padding-top: 8px;

  .ant-form-item + .ant-form-item {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.loginButton.ant-form-item {
  margin-top: 20px;
}

.loginSecurityText {
  margin: 0 4px;
  font-size: 0.85714rem;
}

@media (max-width: 1000px) {
  .loginImageContainer {
    display: none;
  }

  .logoImageContainer {
    display: flex;
    justify-content: center;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';