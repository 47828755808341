@import '../../../../../../style/variables.less';

.NewEmployeeModalQRCodeImageModalBody {
  padding: 16px;
}

.NewEmployeeModalQRCodeImageSkeleton.ant-skeleton-element {
  width: 280px;
  height: 280px;

  .ant-skeleton-avatar-lg {
    width: 100%;
    height: 100%;
  }
}

.NewEmployeeModalQRCodeImageContainer {
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 280px;
  height: 280px;
}

.NewEmployeeModalQRCodeImage.ant-image-img {
  width: 100%;
  height: 100%;

  &.TimeOut {
    filter: blur(6px);
  }
}

.NewEmployeeModalQRCodeImageCover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.NewEmployeeModalQRCodeImageRefreshButton.ant-btn-text {
  padding: 0;
  width: 52px;
  height: 52px;
  background-color: white;
  border-radius: 50%;
}

.NewEmployeeModalQRCodeImageRefreshIcon.anticon svg {
  font-size: 42px;
}

.NewEmployeeModalStores.ant-select {
  margin-top: 16px;
  margin-bottom: 16px;
  min-width: 150px;
}

.NewEmployeeModalCountDown {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 1.142857rem;
  font-weight: @font-weight-bold;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';