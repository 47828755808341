@import '../../../../../../../../style/variables.less';

.AddPlayer {
  .ant-input-group.ant-input-group-compact {
    display: flex;
  }
}

.AddPlayerFooterPromptText {
  font-size: 0.857142rem;
  color: @gray-3;
}

.AddPlayerFooterPromptTextRequired {
  color: @red-color;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';