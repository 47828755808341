@legendBoxShadowColor: #cdcdcd;

.TournamentsTargetsSettingModal {
  height: 98%;

  >* {
    height: 100%;
  }

  .ant-modal-body {
    position: relative;
    height: 100%;
  }
}

.TournamentLegend {
  padding: 24px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-height: 50%;
  background-color: white;
  box-shadow: 0px -5px 50px 0px @legendBoxShadowColor;
  z-index: 100;
}

.TournamentLegendTable.ant-table-wrapper {
  height: auto;
  min-height: 0;

  .ant-table-thead {
    position: sticky;
    top: 0;
    background-color: rgb(255, 242, 242);
    z-index: 3;
  }

  &.TournamentLegendTableAllShownAnimationInitial {
    height: 190px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transform-origin: top;
    transition: all 0.2s ease-in-out;
  }

  .ant-table-cell {
    padding: 8px;
    height: auto;
    min-height: 0;
    font-weight: bold;
    border: 0;
    background-color: transparent;

    >span {
      text-align: inherit;
      font-weight: inherit;
      line-height: 1em;
    }
  }

  .ant-table-row {
    height: auto;
    min-height: 0;

    &:hover,
    &:hover>td {
      background-color: transparent;
    }
  }
}

.TournamentLegendProjectColumn.ant-table-cell {
  text-align: center;
  padding: 8px;
}
.TournamentLegendRoundTitle {
  padding: 4px 0;
  gap: 8px;
  font-style: normal;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.TournamentLegendRoundName {
  padding: 4px 8px;
  border: 1px solid;
  border-radius: 6px;
  font-style: normal;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  &Subtitle {
    font-size: 12px;
    font-weight: 600;
  }
}

.TournamentLegendTableButton {
  min-width: 110px;
}

.TournamentLegendTableButton .TournamentLegendTableButtonIcon.anticon {
  transform: rotate(0);
  transform-origin: center;
  transition: all 0.2s ease-in-out;
}

.TournamentLegendTableButton .TournamentLegendTableCollapseButtonIcon.anticon {
  transform: rotate(180deg);
  transform-origin: center;
  transition: all 0.2s ease-in-out;
}
@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';