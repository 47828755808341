.event_icon_item {
  border: 1px solid rgb(100, 134, 228);
  border-radius: 50%;
}

.content {
  &__filter {
    padding: 24px;
  }
}

.form {
  &__group + &__group {
    margin-left: 20px;
  }

  &__label {
    margin-right: 16px;
  }

  &__item + &__item {
    margin-left: 16px;
  }
}

.event_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event_icon {
  position: absolute;
  right: 5px;
  top: 5px;
  color: rgb(100, 134, 228);
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AuditPlayerSearch.ant-select {
  margin-right: 16px;
  min-width: 180px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';