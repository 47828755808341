@import '../../../../../style/variables.less';

.cancelOrderButton.ant-btn {
  margin-right: 23px;
  line-height: 24px;
  width: 140px;
  height: 42px;
  padding: 7px 16px;
  border: 2px solid;
  border-radius: 7px;
  border-color: @primary-color;
  color: @primary-color;
}

.cancelOrderButtonText {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';