@import '../../../../../../style/variables.less';

.EnrollmentResultButtonContainer {
  padding: 16px 24px;
}

.EnrollmentResultSummaryTitle {
  font-weight: bold;
}

.EnrollmentResultSummaryBlock {
  padding: 16px 24px;
  border: 1px solid @gray-4;
  border-radius: 8px;
}

.EnrollmentResultSummarySubtitle {
  margin-bottom: 10px;
  color: @primary-color;
}

.EnrollmentResultSummaryTotal {
  font-size: 2.714285rem;
  line-height: 46px;
  color: @primary-color;
  font-weight: bold;
}

.EnrollmentResultSummaryDataList {
  gap: 10px;
}

.EnrollmentResultSummaryData {
  list-style: none;
  font-size: 2.714285rem;
  line-height: 46px;
  color: @primary-color;
  font-weight: bold;

  &.green {
    font-size: 1.7142857143rem;
    color: #3bb888;
  }

  &.pink {
    font-size: 1.7142857143rem;
    color: #f97d96;
  }
}

.EnrollmentResultFilter {
  padding: 24px;

  .ant-form-item {
    margin-bottom: 18px;
  }
}

.EnrollmentResultNameTag {
  text-align: center;
  min-width: 60px;
}

.EnrollmentResultClubInfoModal {
  .ant-modal-confirm-btns {
    display: none;
  }
}

.EnrollmentResultClubInfoImageContainer {
  width: 200px;
  height: 200px;
}

.EnrollmentResultClubInfoImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.EnrollmentResultClubInfoLeaderText {
  font-size: 1.1428571429rem;
  font-weight: bold;
}

.EnrollmentResultDownload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.EnrollmentResultDownloadProgress {
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  padding: 16px;
  margin: auto;
  width: 300px;
  background-color: @white;
  border-radius: 8px;
}

.EnrollmentResultDownloadProgressTip {
  margin-top: 8px;
  margin-bottom: 8px;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';