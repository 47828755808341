@import '../../style/variables.less';

.memberCardContainer {
  padding: 0 24px;
}

.memberCardAutoGenerateNumber {
  margin-bottom: 16px;
}

.memberCardItem {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 1px 1px 28px -2px rgba(0, 0, 0, 0.3);
}

.memberCardAddItem {
  box-shadow: none;
}

.memberCardContent {
  position: relative;
  width: 100%;
  padding: 32% 0;
  cursor: pointer;
}

.memberCardContentImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.memberCardContentDetails,
.ant-btn.memberCardAddButton,
.memberCardContentDeletedDetails {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.memberCardContentDetails {
  color: @gray-4;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;

  &:hover {
    transition: opacity 0.4s;
    opacity: 1;
  }
}

.memberCardContentTitle {
  color: @gray-4;
}

.memberCardContentDescription {
  font-size: 0.8rem;
}

.ant-btn.memberCardAddButton {
  border-style: dashed;
}

.memberCardAddIcon {
  font-size: 3rem;
}

.memberCardContentDeletedDetails {
  background-color: rgba(255, 255, 255, 0.5);
}

.memberCardContentRevertButton.ant-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
}

.memberCardRevertButtonIcon.anticon {
  color: @green-color;
}

.memberCardContentDeletedImage {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

.memberCardDeleteButton.ant-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.memberCardDeleteButtonIcon.anticon {
  color: @red-color;
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';