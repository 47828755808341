@import '../../../../../../style/variables.less';

.merchandiseDetailFormContainer {
  padding: 0 24px;
  max-width: 448px;
}

.outlineButton.ant-btn {
  color: @primary-color;
  border: 1px solid @primary-color;
}

.merchandise-drag {
  &.hidden {
    display: none;
  }

  .ant-upload.ant-upload-select {
    display: block;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }

  .ant-upload .ant-upload-drag-container p.ant-upload-drag-icon {
    margin-bottom: 0;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';