@import '../../../../../../style/variables.less';

.EnrollmentDetailFormContainer {
  padding: 0 24px;
  max-width: 850px;
}

.EnrollmentDetailFormItem {
  max-width: 400px;
}

.EnrollmentDetailLimitationTile {
  font-size: 12px;
}

.ant-form-vertical .EnrollmentDetailLimitationType {
  margin-bottom: 0;

  .ant-form-item-row {
    flex-direction: row;
  }

  .ant-form-item-label > label,
  .formItemLabel {
    margin: 8px 8px 8px 0;
  }

  &.ant-form-item .ant-form-item-control {
    width: auto;
  }
}

.EnrollmentDetailFormItemInputNumber.ant-input-number {
  width: 100%;
  max-width: 235px;
}

.EnrollmentDetailFormItemUploader.ant-upload.ant-upload-drag {
  overflow: hidden;

  .ant-upload-btn {
    min-height: 250px;
    padding: 0;
  }
}

.EnrollmentSettingsStoreSelector {
  min-width: 120px;

  .ant-select-selection-search {
    min-width: inherit;
  }
}

.EnrollmentDetailCheckListButtonContainer {
  padding: 16px 24px;
}

.EnrollmentDetailCheckListButton.ant-btn {
  border: 2px solid;
  border-radius: 7px;
  padding: 7px 16px;
  min-width: 146px;
  height: 42px;
  font-size: 16px;
  line-height: 24px;
  border-color: @primary-color;
  color: @primary-color;
  z-index: 1;
}

.EnrollmentDetailsAttribute {
  margin-bottom: 24px;
}

.EnrollmentDetailsAttributeTitle {
  margin-bottom: 16px;
  font-weight: normal;
}

.EnrollmentDetailsAttributeItem {
  margin: 0 20px;
  list-style: none;
}

.EnrollmentDetailsAttributeBasic {
  gap: 10px;
}

.EnrollmentDetailsAttributeNameItem {
  max-width: 160px;
}

.EnrollmentDetailsAttributeDescriptionItem {
  max-width: 180px;
}

.EnrollmentDetailsAttributeTypeItem {
  margin-bottom: 0;
}

.EnrollmentDetailsAttributeTypeOptionList.ant-select.ant-select-in-form-item {
  width: 265px;

  .ant-select-selection-item-content {
    font-size: 0.85714rem;
  }
}

.EnrollmentDetailsAttributeItemAddButton {
  margin: 0 20px;
  max-width: 400px;
}

.EnrollmentDetailsAttributeItemAddButtonContent > span {
  font-size: 12px;
}

.EnrollmentDetailsTurnedOn .ant-row.ant-form-item-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 -10px;

  .ant-form-item-label {
    padding-bottom: 0;
    margin: 0 10px;
  }

  .ant-form-item-control {
    width: auto;
  }
}

.EnrollmentDetailsTurnedOnSwitchText {
  margin: 0 4px;
}

@media (max-width: 760px) {
  .EnrollmentDetailCheckListButton.ant-btn {
    padding: 0;
    border: 0;
    min-width: 0;
  }

  .EnrollmentDetailCheckListButtonProfileIcon.anticon-profile {
    font-size: 24px;
  }

  .EnrollmentDetailCheckListButton > span.EnrollmentDetailCheckListButtonText {
    display: none;
  }
}

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';